import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EmployeeIn } from '../../../models/employee/employee-in';
import { SimplePaymentOut } from '../../../models/payments/simple-payment/simple-payment-out';
import { EmployeesService } from '../../../services/employees.service';
import { FullEmployeesListComponent } from '../../employees/full-employees-list/full-employees-list.component';
import { faSpinner as faLoader } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-simple-payment-form',
  standalone: true,
  imports: [FormsModule, CommonModule, FullEmployeesListComponent, FontAwesomeModule, TranslateModule],
  templateUrl: './simple-payment-form.component.html',
  styleUrl: './simple-payment-form.component.scss'
})
export class SimplePaymentFormComponent {
  constructor(private shared: SharedService) {}
  @Input() payment = new SimplePaymentOut()

  @Input() walletBalance = 0
  @Input() working = false
  @Input('selectedEmployee') employee: EmployeeIn | null = null
  @Output() onContinue = new EventEmitter<SimplePaymentOut>()
  @Output() onBack = new EventEmitter()

  icons = {faLoader}
  accountId = 0

  accountName(account: any) {
    return this.shared.accountName(account)
  }

  continue() {
    this.payment.resetErrors()
    if(500 > this.payment.amount) {
      this.payment.amount_error_message = "simple_payment.amount_too_small"
      this.payment.amount_error = true
    }
    
    if(500000 < this.payment.amount) {
      this.payment.amount_error_message = "simple_payment.amount_too_big"
      this.payment.amount_error = true
    }
    if("" == this.payment.concept) {
      this.payment.concept_error_message = "simple_payment.concept_empty_error"
      this.payment.concept_error = true
    }

    if (!(this.payment.concept_error || this.payment.amount_error)) {

      this.employee!!.main_withdrawal_method.type = this.employee?.accounts[this.accountId].type
      this.employee!!.main_withdrawal_method.operator_id = this.employee?.accounts[this.accountId].operator_id
      this.employee!!.main_withdrawal_method.identifier = this.employee?.accounts[this.accountId].identifier
      this.employee!!.main_withdrawal_method.id = this.employee?.accounts[this.accountId].id

      this.payment.payee = this.employee!!
      this.onContinue.emit(this.payment)
    }
  }
  goBack() {
    this.onBack.emit()
  }

  cancel() {
    history.back()
  }

  ngOnInit() {
  }
}
