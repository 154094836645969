import { Component, OnInit } from '@angular/core';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import {
  faClock,
  faDashboard,
  faExchange as faTransactions,
  faPeopleGroup as faEmployees,
  faBell as faNotifications,
  faSignOut,
  faUser as faAccount,
  faQuestion as faHelp,
  faBoxesPacking as faProviders
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { BusinessService } from '../../services/business.service';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [MenuItemComponent, FontAwesomeModule, TranslateModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit {
  icons = { faClock, faDashboard, faTransactions, faEmployees, faNotifications, faSignOut, faAccount, faProviders, faHelp }

  constructor(private authService: AuthService, private businessData: BusinessService) {
  }

  get business() {
    return this.businessData.infos
  }

  ngOnInit(): void {
    this.businessData.getInfos().subscribe(_ => { })
  }

  onLogout() {
    this.authService.signOut();
  }
}
