<div class="w-full items-center hidden md:flex justify-between relative">
    <hr class="colors-primary-border w-[100%] mb-12 absolute z-0">
    <div
        class="flex w-4 mb-12 colors-primary-bg text-white h-4 border rounded-full colors-primary-border justify-center items-center">
        &nbsp;</div>
    <ng-container *ngFor="let step of steps; let i = index">
        <div class="flex flex-col items-center justify-center z-10">
            <div class="flex w-8 align-center colors-primary-bg text-white h-8 border rounded-full colors-primary-border justify-center items-center"
                [ngClass]="{'colors-primary-bg text-white': currentStep >= i, 'colors-background colors-primary-text': currentStep < i}">
                <ng-container *ngIf="currentStep > i">
                    &check;
                </ng-container>
                <ng-container *ngIf="currentStep <= i">
                    {{i + 1}}
                </ng-container>
            </div>
            <p class="mt-6" [ngClass]="{'colors-primary-text': currentStep >= i}">{{step | translate}}</p>
        </div>
    </ng-container>
    <div
        class="flex w-4 mb-12 colors-primary-bg text-white h-4 border rounded-full colors-primary-border justify-center items-center">
        &nbsp;</div>
</div>