import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment.pre';
import { BusinessIn, BusinessInWrapped } from '../models/business/business-in';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  baseURL = `${environment.apiUrl}/businesses/me`
  loaded: boolean = false
  infos: BusinessIn | null = null

  constructor(private http: HttpClient) { }


  getInfos(): Observable<BusinessIn> {
    if (this.loaded) return of(this.infos!!)
    return this.http.get<BusinessInWrapped>(`${this.baseURL}`).pipe(
      map(data => {
        this.loaded = true
        return this.infos = data.business
      })
    );
  }
}
