<section
  class="animate-pulse-children space-y-12 rounded-2xl relative w-full border colors-primary-dark-border py-4 colors-surface overflow-x-hidden">
  <div class="bg-white rounded-lg p-3">
    <div class="flex items-center">
      <svg width="34px" height="34px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#10004c">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path fill="#10004c" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path>
          <path fill="#10004c"
            d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z">
          </path>
        </g>
      </svg>
      <span class="text-lg ml-4 font-bold w-44 colors-background">&nbsp;&nbsp;</span>
    </div>
  </div>

  <div class="flex flex-col space-y-3 px-10 mt-4">
    <div class="rounded-lg">
      <ul class="divide-y">
        <li
          class="py-5 bg-white cursor-pointer flex items-start md:justify-between border px-4 rounded-lg flex-col sm:flex-row">
          <div class="flex gap-3">
            <div class="flex-none w-12 h-12 colors-background rounded-full">
            </div>
            <div>
              <span class="block text-lg font-medium colors-background w-36">&nbsp;&nbsp;</span>
              <span class="block text-xs colors-background w-40">&nbsp;&nbsp;</span>
            </div>
          </div>
          <div class="flex flex-col items-center colors-background">
            <span class="colors-background w-32">&nbsp;&nbsp;</span>
            <p class="text-center w-32 colors-background">&nbsp;&nbsp;</p>
          </div>
          <span class="colors-background w-26">&nbsp;&nbsp;</span>
        </li>
      </ul>
    </div>

    <div class="bg-white shadow-lg rounded-lg overflow-hidden">
      <div class="px-6 py-4 space-y-4">
        <p class="flex justify-between colors-background">
          <span>&nbsp;&nbsp;</span>
          <span>&nbsp;&nbsp;</span>
        </p>
        <p class="flex justify-between colors-background">
          <span>&nbsp;&nbsp;</span>
          <span>&nbsp;&nbsp;</span>
        </p>
        <p class="flex justify-between colors-background">
          <span>&nbsp;&nbsp;</span>
          <span>&nbsp;&nbsp;</span>
        </p>
        <p class="flex justify-between colors-background">
          <span>&nbsp;&nbsp;</span>
          <span>&nbsp;&nbsp;</span>
        </p>
        <p class="flex text-xl font-bold items-center colors-background justify-between pt-8">
          <span>&nbsp;&nbsp;</span>
          <span>&nbsp;&nbsp;</span>
        </p>
      </div>
    </div>
  </div>
</section>
