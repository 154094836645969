@if (showPaymentProgress) {
<div class="flex items-center justify-center">
  <div class="relative">
    <div class="p-10 test text-center text-white rounded-3xl items-center text-2xl justify-center">
      <button class="absolute top-4 right-4 rounded-2xl justify-end text-4xl payment-progress-text-color"
        (click)="close()">&times;</button>
      <div class="sm:mx-auto sm:w-full fonts-bold text-2xl payment-progress-text-color sm:max-w-sm">
        @if (status === 'PENDING' || status === 'ON_HOLD') {
        <p class="text-center text-3xl fonts-bold leading-9 tracking-tight">
          {{'payment.progress.pending.title' | translate}}
        </p>
        } @else {
        <p class="text-center text-3xl fonts-bold leading-9 tracking-tight">
          {{'payment.progress.completed.title' | translate}} </p>
        }
      </div>
      <div class="mt-12 font-medium text-center sm:w-full sm:max-w-sm">

        @if (status === 'PENDING') {
        <div>
          <p>{{'payment.progress.pending.description' | translate}}</p>
        </div>

        <svg class="animate-spin mx-auto h-20 w-auto mt-20" width="66" height="66" viewBox="0 0 66 66" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M62 33C62 36.8083 61.2499 40.5794 59.7925 44.0978C58.3351 47.6163 56.199 50.8132 53.5061 53.5061C50.8132 56.199 47.6163 58.3351 44.0978 59.7925C40.5794 61.2499 36.8083 62 33 62C29.1917 62 25.4206 61.2499 21.9022 59.7925C18.3837 58.3351 15.1868 56.199 12.4939 53.5061C9.801 50.8132 7.66488 47.6163 6.20749 44.0978C4.7501 40.5794 4 36.8083 4 33C4 29.1917 4.75011 25.4206 6.2075 21.9022C7.66489 18.3837 9.80101 15.1868 12.4939 12.4939C15.1868 9.801 18.3837 7.66487 21.9022 6.20749C25.4206 4.7501 29.1917 4 33 4C36.8083 4 40.5794 4.75011 44.0978 6.2075C47.6163 7.66489 50.8132 9.80101 53.5061 12.4939C56.199 15.1868 58.3351 18.3838 59.7925 21.9022C61.2499 25.4206 62 29.1917 62 33L62 33Z"
            stroke="#EBE5FF" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M12.4939 12.4939C15.73 9.25783 19.686 6.83358 24.0385 5.41936C28.391 4.00514 33.0164 3.64112 37.5366 4.35704C42.0568 5.07296 46.3433 6.84851 50.0458 9.53851C53.7482 12.2285 56.7615 15.7566 58.8392 19.8343C60.9169 23.912 62 28.4235 62 33C62 37.5765 60.9169 42.088 58.8392 46.1657C56.7615 50.2434 53.7482 53.7715 50.0458 56.4615C46.3433 59.1515 42.0567 60.927 37.5366 61.643"
            stroke="#FCA600" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <p class="mt-10">{{'payment.progress.pending.waiting.msg' | translate}}</p>
        } @else if (status === 'COMPLETED') {
        <svg class="mx-auto animate-bounce" fill="#f1f4f1" width="72px" height="72px" viewBox="0 0 1920 1920"
          xmlns="http://www.w3.org/2000/svg" stroke="#f1f4f1">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M960 1807.059c-467.125 0-847.059-379.934-847.059-847.059 0-467.125 379.934-847.059 847.059-847.059 467.125 0 847.059 379.934 847.059 847.059 0 467.125-379.934 847.059-847.059 847.059M960 0C430.645 0 0 430.645 0 960s430.645 960 960 960 960-430.645 960-960S1489.355 0 960 0M854.344 1157.975 583.059 886.69l-79.85 79.85 351.135 351.133L1454.4 717.617l-79.85-79.85-520.206 520.208Z"
              fill-rule="evenodd"></path>
          </g>
        </svg>

        <p class="mt-10">{{'payment.progress.completed.description' | translate}}</p>
        } @else if (status === 'ON_HOLD') {
        <svg class="mx-auto" width="72px" height="72px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
          class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#FCA600">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M36.989 42.439H27.01L23 2h18z" fill="#FCA600"></path>
            <ellipse cx="31.999" cy="54.354" rx="7.663" ry="7.646" fill="#FCA600"></ellipse>
          </g>
        </svg>
        <p class="mt-10">{{'payment.progress.onhold.description' | translate}}</p>
        } @else {
        <svg class="mx-auto" fill="#FCA600" width="72px" height="72px" viewBox="0 0 32 32" version="1.1"
          xmlns="http://www.w3.org/2000/svg" stroke="#FCA600">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <title>cancel</title>
            <path
              d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z">
            </path>
          </g>
        </svg>
        <p class="mt-5">{{'payment.progress.failed.description' | translate}}</p>
        }
      </div>

      <div class="mt-10 font-medium text-center sm:w-full sm:max-w-sm">
        @if (mobileOperator === 'orange_money' && status === 'PENDING') {
        <div class="my-8">
          <p>{{'payment.progress.please.dial' | translate}}<span class="payment-progress-text-color"> #150*50# </span>
            {{'payment.progress.please.dial.text' | translate}}</p>
        </div>
        }

        @if (mobileOperator === 'mtn_mobile_money' && status === 'PENDING') {
        <div class="my-8">
          <p>{{'payment.progress.please.dial' | translate}}<span class="payment-progress-text-color"> *126#
            </span>{{'payment.progress.please.dial.text' | translate}}
          </p>
        </div>
        }

        @if (showButton) {
        <div class="flex items-center space-x-2"
          [ngClass]="{'justify-center': status !== 'COMPLETED', 'justify-start': status === 'COMPLETED'}">
          <button (click)="buttonActionClicked()"
            class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group payment-progress-button">
            <span
              class="relative fonts-bold px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
              {{textButton | translate}}
            </span>
          </button>
          @if (status === 'COMPLETED') {
          <app-transfer-receipt
            class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group payment-progress-button"
            [transactionId]="transaction_id" [fromPaymentProgress]="true"></app-transfer-receipt>
          }
        </div>
        }
      </div>
    </div>
  </div>
</div>
}
