<article (document:mousemove)="mouseMoved($event)">
    <section class="w-full relative overflow-y-hidden h-[100vh] lg:colors-primary-dark-bg" *ngIf="!menuVisible; else menu">
        <div
            class="colors-primary-text w-full relative colors-primary-dark-bg text-lg fonts-bold inline-flex justify-between items-center">
            <div class="px-4 lg:px-8 py-6 lg:pt-16 lg:pb-4 inline-flex lg:absolute lg:w-menu-panel items-center">
                <fa-icon class="mr-4 text-2xl lg:hidden" (click)="toggleMenu()" [icon]="icons.faBars"></fa-icon>
                <fa-icon class="mr-8 text-2xl hidden lg:block" [icon]="icons.faBars"></fa-icon>
                <div #customTitle>
                    <ng-content select="[title]"></ng-content>
                </div>
                <ng-container *ngIf="customTitle.children.length == 0">
                    <h1>
                        <img src="assets/logo_white_2x.png" class="h-title" alt="Futa white logo" />
                    </h1>
                </ng-container>
            </div>
            <div
                class="lg:w-full lg:ml-menu-panel lg:pt-16 lg:pb-4 inline-flex justify-between items-center lg:colors-background self-stretch">
                <h2 class="pl-8 hidden lg:block text-3xl font-bold">{{pageTitle}}</h2>
                <div class="inline-flex items-center pr-4 lg:pr-8">
                    <!-- <fa-icon class="mr-4 text-2xl cursor-pointer" (click)="onNotifClicked()" [icon]="icons.faBell">
                    </fa-icon> -->
                    <ng-container *ngIf="showBalance">
                        <p class="mr-4">{{'dashboard.balance' | translate}}: {{showAmount()}}</p>
                    </ng-container>
                    <div #customActionButton>
                        <ng-content select="[customActionButton]"></ng-content>
                    </div>
                    <ng-container *ngIf="customActionButton.children.length == 0">
                        <span *ngIf="hasAction" (click)="onActionClicked()"
                            class="border colors-primary-border cursor-pointer colors-primary-bg inline-flex items-center rounded-2xl px-2 py-1">
                            <div #customActionIcon>
                                <ng-content select="[actionIcon]"></ng-content>
                            </div>
                            <ng-container *ngIf="customActionIcon.children.length == 0">
                                <fa-icon class="mr-2" [icon]="icons.faPlus"></fa-icon>
                            </ng-container>
                            <p>{{actionLabel}}</p>
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>
        <div
            class="hidden absolute lg:block w-menu-panel colors-primary-dark-bg px-4 py-8 text-lg inline-flex row items-center">
            <app-menu></app-menu>
        </div>
        <div class="lg:ml-menu-panel block w-fill min-h-[100vh] colors-background py-6 px-4 lg:px-8 lg:pt-8">
            <div class="lg:hidden flex w-full justify-between items-center">
                <h2 class="text-2xl fonts-bold mb-4">{{pageTitle}}</h2>
            </div>
            <div class="max-h-[95vh] overflow-y-scroll pb-16">
                <div class="h-fill overflow-y-visible pb-16">
                    <ng-content select="[pageContent]"></ng-content>
                </div>
            </div>
        </div>
    </section>

    <ng-template #menu>
        <section class="fixed w-full">
            <div
                class="lg:hidden colors-primary-text w-full colors-primary-dark-bg px-4 py-8 pb-6 text-lg flex flex-row justify-between items-center">
                <ng-container *ngIf="customMenuTitle.children.length == 0">
                    <h1>
                        <img src="assets/logo_white_2x.png" alt="Futa white logo" class="h-title">
                    </h1>
                </ng-container>
                <div #customMenuTitle>
                    <ng-content select="[menuTitle]"></ng-content>
                </div>
                <fa-icon class="mr-4 text-2xl" [icon]="icons.faClose" (click)="toggleMenu()"></fa-icon>
            </div>
            <div
                class="lg:hidden w-full colors-primary-dark-bg pb-[100vh] pt-16 px-4 text-lg inline-flex row items-center">
                <app-menu></app-menu>
            </div>
        </section>
    </ng-template>
</article>
