import { Component, Input, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import { BusinessService } from '../../services/business.service';
import { CommonModule, formatDate } from '@angular/common';
import { TransactionService } from '../../services/transaction.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Formatters } from '../../helpers/formatters';

@Component({
  selector: 'app-transfer-receipt',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './transfer-receipt.component.html',
  styleUrl: './transfer-receipt.component.scss'
})
export class TransferReceiptComponent implements OnInit {
  @Input() receiptDetails: any;
  @Input() transactionId: string = '';
  @Input() fromPaymentProgress: boolean = false;
  // @Input() useIcon: boolean = false;
  businessData: any;

  constructor(
    private businessService: BusinessService,
    private transactionService: TransactionService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.businessService.getInfos().subscribe(data => {
      this.businessData = data;
      // this.receiptDetails = data;
    })

    if (this.transactionId !== '') {
      this.transactionService.getTransactionDetails(this.transactionId).subscribe((data: any) => {
        this.receiptDetails = data

        this.receiptDetails.details.fees_formatted = Formatters.formatAmount(data.details.fees.value, 'XAF', this.translateService.currentLang);
        this.receiptDetails.details.total_amount_formatted = Formatters.formatAmount(data.details.total_amount.value, 'XAF', this.translateService.currentLang);
      });
    }
  }

  async generatePDF() {
    // Create new PDF document
    const doc = new jsPDF();

    doc.addImage('../assets/logo.png', 'PNG', 20, 20, 40, 20);

    // Set fonts
    doc.setFont('helvetica', 'normal');

    // Header
    doc.setFontSize(24);
    const title = await this.translateService.get('transfer.receipt.title').toPromise();
    doc.text(title, 190, 30, { align: 'right' });

    doc.setFontSize(10);
    const subTitle = await this.translateService.get('transfer.receipt.subtitle').toPromise() + ' ' + new Date().toLocaleDateString();
    doc.text(subTitle, 190, 40, { align: 'right' });

    // Sender details
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12);
    doc.text(this.businessData.name.toUpperCase(), 20, 60);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(this.businessData.address, 20, 70);

    doc.setTextColor(0, 0, 0);

    // Right side details
    const rightColumnX = 120;

    this.rightSideDetails(doc, rightColumnX, this.receiptDetails.details);

    // Transfer details
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(14);
    const transferDetailsText = await this.translateService.get('transfer.receipt.transfer.details').toPromise();
    doc.text(transferDetailsText, 20, 130);
    doc.setFont('helvetica', 'normal');

    doc.setFontSize(10);
    const transferDetailsY = 150;

    await this.transferDetails(doc, transferDetailsY, this.receiptDetails.details);

    // Beneficiary details
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(14);
    const beneficiaryText = await this.translateService.get('transfer.receipt.beneficiary.details').toPromise();
    doc.text(beneficiaryText, 20, 200);
    doc.setFont('helvetica', 'normal');

    doc.setFontSize(10);
    const beneficiaryY = 220;

    await this.beneficiaryDetails(doc, beneficiaryY, this.receiptDetails.details);

    // Add some styling - light gray backgrounds for sections
    doc.setFillColor(247, 247, 247);
    doc.rect(rightColumnX - 5, 65, 80, 40, 'F'); // Right column background
    doc.rect(15, transferDetailsY - 10, 110, 45, 'F'); // Transfer details background
    doc.rect(15, beneficiaryY - 10, 110, 35, 'F'); // Beneficiary details background

    doc.setTextColor(0, 0, 0);

    // Add text inside the rectangles
    await this.rightSideDetails(doc, rightColumnX, this.receiptDetails.details);
    await this.transferDetails(doc, transferDetailsY, this.receiptDetails.details);
    await this.beneficiaryDetails(doc, beneficiaryY, this.receiptDetails.details);


    // Save the PDF
    doc.save(`${this.businessData.name?.replace(/\s/g, "_")}_${this.receiptDetails.details.peer_full_name.replace(/\s/g, "_")}_${Number(new Date())}.pdf`)
  }

  async rightSideDetails(doc: any, rightColumnX: number, rsDetails: any) {
    // doc.text('Value Date', rightColumnX, 70);
    // doc.text('2024-09-24', 190, 70, { align: 'right' });

    const operationDate = await this.translateService.get('transfer.receipt.operation_date').toPromise();
    doc.text(operationDate, rightColumnX, 80);
    doc.text(formatDate(rsDetails.performed_at, 'dd/MM/yyyy', 'fr'), 190, 80, { align: 'right' });

    const dateAvailable = await this.translateService.get('transfer.receipt.date_available').toPromise();
    doc.text(dateAvailable, rightColumnX, 90);
    doc.text(formatDate(rsDetails.performed_at, 'dd/MM/yyyy', 'fr'), 190, 90, { align: 'right' });

    const status = await this.translateService.get('transfer.receipt.status').toPromise();
    doc.text(status, rightColumnX, 100);
    doc.text(rsDetails.status, 190, 100, { align: 'right' });

    // doc.text('Type', rightColumnX, 110);
    // doc.text('SEPA Instant Credit Transfer', 190, 110, { align: 'right' });
  }

  async transferDetails(doc: any, transferDetailsY: number, tDetails: any, startText = 120) {
    const amount = await this.translateService.get('bulk.payment.amount').toPromise();
    doc.text(amount, 20, transferDetailsY);
    doc.text(tDetails.total_amount_formatted, startText, transferDetailsY, { align: 'right' });

    const fees = await this.translateService.get('bulk.payment.fees').toPromise();
    doc.text(fees, 20, transferDetailsY + 10);
    doc.text(tDetails.fees_formatted, startText, transferDetailsY + 10, { align: 'right' });

    const concept = await this.translateService.get('bulk.payment.concept').toPromise();
    doc.text(concept, 20, transferDetailsY + 20);
    doc.text(tDetails.concept, startText, transferDetailsY + 20, { align: 'right' });

    // doc.text('Transaction Reference', 20, transferDetailsY + 30);
    // doc.text('REVITR24092496222594378', startText, transferDetailsY + 30, { align: 'right' });
  }

  async beneficiaryDetails(doc: any, beneficiaryY: number, bDetails: any) {
    const name = await this.translateService.get('business.infos_name').toPromise();
    doc.text(name, 20, beneficiaryY);
    doc.text(bDetails.peer_full_name, 120, beneficiaryY, { align: 'right' });

    const identifier = await this.translateService.get('topup.step2.identifier').toPromise();
    doc.text(identifier, 20, beneficiaryY + 10);
    doc.text(bDetails.peer_identifier, 120, beneficiaryY + 10, { align: 'right' });

    const operator = await this.translateService.get('transfer.receipt.operator').toPromise();
    doc.text(operator, 20, beneficiaryY + 20);
    doc.text(this.getOperatorName(bDetails.peer_operator_id).toUpperCase(), 120, beneficiaryY + 20, { align: 'right' });
  }

  getOperatorName(operatorId: string) {
    const operators = {
      orange_money: 'Orange Money',
      mtn_mobile_money: 'MTN Mobile Money',
      express_union: 'Express Union Mobile Money'
    }

    return operators[operatorId as keyof typeof operators] || '';
  }
}
