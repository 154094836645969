<section class="w-full bg-white md:px-8 px-0 mt-1 md:mt-[1vh]">
    <div class="p-1 md:p-8 mb-24 bg-white">
        <div class="flex justify-between items-start">
            <div class="flex justify-center">
                <p class="text-2xl md:text-3xl fonts-bold mb-6 md:mb-16">{{'simple_payment.title' | translate}}</p>
            </div>
            <button class="px-2 py-2 w-[32] h-[32] text-4xl" (click)="cancel()">&times;</button>
        </div>
        <form class="grid grid-flow-row grid-cols-1 mb-4 md:grid-cols-1 gap-1 md:gap-4">
            <div class="inputs-group-span-1">
                <label for="concept">{{'simple_payment.concept' | translate}}</label>
                <input [(ngModel)]="payment.concept" class="inputs-field-default" [ngClass]="{'colors-border-error focus:outline-red-500': payment.concept_error}" type="text" name="concept" id="concept">
                <label for="concept" class="alert-error px-2 py-1" *ngIf="payment.concept_error">{{payment.concept_error_message | translate}}</label>
            </div>
            <div class="inputs-group-span-1">
                <label for="amount">{{'simple_payment.amount' | translate}}</label>
                <input [(ngModel)]="payment.amount" class="inputs-field-default" [ngClass]="{'colors-border-error focus:outline-red-500': payment.amount_error}" type="number" name="amount" id="amount">
                <label for="amount" class="alert-error px-2 py-1" *ngIf="payment.amount_error">{{payment.amount_error_message | translate}}</label>
            </div>
            <div class="inputs-group-span-1">
                <label for="account">{{'simple_payment.account' | translate}}</label>
                <select [(ngModel)]="accountId" class="inputs-field-default" name="account" id="account">
                    <ng-container *ngFor="let a of employee!!.accounts; let i = index">
                        <option [ngValue]="i">
                            {{accountName(a)}} [{{a.identifier}}]
                        </option>
                    </ng-container>
                </select>
            </div>
            <div class="inputs-group-span-full rounded-none">
                <div class="flex justify-between">
                    <p>{{'simple_payment.employee' | translate}}</p>
                </div>
                <div class="border bg-gray-50 p-4">
                    <p class="w-full flex justify-between mb-4">
                        <span class="text-lg">{{'simple_payment.firstname' | translate}}</span>
                        <span class="fonts-bold text-lg">{{employee!!.first_name}}</span>
                    </p>
                    <p class="w-full flex justify-between mb-4">
                        <span class="text-lg">{{'simple_payment.lastname' | translate}}</span>
                        <span class="fonts-bold text-lg">{{employee!!.last_name}}</span>
                    </p>
                </div>
            </div>
        </form>
        <div class="w-full md:w-[calc(100%-284px)] fixed md:absolute md:left-[284px] bottom-0 left-0 px-4 md:px-8">
            <div class="bg-white w-full flex justify-center py-4">
                <button class="px-8 py-2 border colors-primary-border colors-primary-text rounded-lg self-start mr-2" (click)="goBack()"><span class="mr-2 font-black">&lang;</span>{{'simple_payment.action_back' | translate}}</button>
                <button class="px-8 py-2 colors-primary-bg rounded-lg self-start" (click)="continue()" *ngIf="!working">{{'simple_payment.action_continue' | translate}}<span class="ml-2 font-black">&rang;</span></button>
                <button class="px-8 py-2 colors-primary-bg rounded-lg self-start flex disabled" *ngIf="working"><p class="animate animate-spin mr-4"><fa-icon [icon]="icons.faLoader"></fa-icon></p>{{'simple_payment.action_working' | translate}}</button>
            </div>
        </div>
    </div>
</section>

