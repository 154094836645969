<section
  class="animate-pulse-children space-y-12 rounded-2xl relative w-full border colors-primary-dark-border py-4 colors-surface overflow-x-hidden">
  <div class="colors-surface rounded-lg colors-background">
    <div class="flex justify-between items-center px-4">
      @for (item of numbers; track $index) {
      <ng-container>
        <div class="relative flex-col sm:flex-row hidden sm:block">
          <button class="colors-background mr-3 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
                  rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center flex-col sm:flex-row w-28 h-8">
          </button>
        </div>
      </ng-container>
      }
      <span class="md:ml-auto mt-2 sm:mt-0 colors-background w-32 h-8"> &nbsp;&nbsp; </span>
    </div>

  </div>

  <div class="colors-surface rounded-lg">
    <div class="mx-auto px-4">
      <div class="items-start justify-between sm:flex">
        <div class="hidden sm:block">
          <p class="text-base sm:text-sm colors-background w-44">&nbsp;</p>
        </div>
        <p class="flex colors-background w-52">
          <span>&nbsp;</span>
        </p>
      </div>
      <ul class="mt-4 divide-y">
        <li
          class="py-5 colors-background flex items-start md:justify-between border px-4 rounded-lg my-5 flex-col sm:flex-row">
          <div class="flex gap-3">
            <div class="flex-none w-12 h-12 rounded-full colors-surface">
            </div>
            <div class="flex flex-col space-y-1">
              <span class="block text-sm font-medium w-36 colors-surface">&nbsp;</span>
              <span class="block w-44 colors-surface">&nbsp;</span>
            </div>
          </div>
          <span class="sm:flex-row ml-14 w-32 colors-surface">&nbsp;</span>
          <div class="flex flex-col space-y-1 items-center">
            <span class="flex-col sm:flex-row w-36 colors-surface">&nbsp;</span>
            <span class="flex-col sm:flex-row w-28 colors-surface">&nbsp;</span>
          </div>
        </li>
        <li
          class="py-5 colors-background flex items-start md:justify-between border px-4 rounded-lg my-5 flex-col sm:flex-row">
          <div class="flex gap-3">
            <div class="flex-none w-12 h-12 rounded-full colors-surface">
            </div>
            <div class="flex flex-col space-y-1">
              <span class="block text-sm font-medium w-36 colors-surface">&nbsp;</span>
              <span class="block w-44 colors-surface">&nbsp;</span>
            </div>
          </div>
          <span class="sm:flex-row ml-14 w-32 colors-surface">&nbsp;</span>
          <div class="flex flex-col space-y-1 items-center">
            <span class="flex-col sm:flex-row w-36 colors-surface">&nbsp;</span>
            <span class="flex-col sm:flex-row w-28 colors-surface">&nbsp;</span>
          </div>
        </li>
        <li
          class="py-5 colors-background flex items-start md:justify-between border px-4 rounded-lg my-5 flex-col sm:flex-row">
          <div class="flex gap-3">
            <div class="flex-none w-12 h-12 rounded-full colors-surface">
            </div>
            <div class="flex flex-col space-y-1">
              <span class="block text-sm font-medium w-36 colors-surface">&nbsp;</span>
              <span class="block w-44 colors-surface">&nbsp;</span>
            </div>
          </div>
          <span class="sm:flex-row ml-14 w-32 colors-surface">&nbsp;</span>
          <div class="flex flex-col space-y-1 items-center">
            <span class="flex-col sm:flex-row w-36 colors-surface">&nbsp;</span>
            <span class="flex-col sm:flex-row w-28 colors-surface">&nbsp;</span>
          </div>
        </li>
        <li
          class="py-5 colors-background flex items-start md:justify-between border px-4 rounded-lg my-5 flex-col sm:flex-row">
          <div class="flex gap-3">
            <div class="flex-none w-12 h-12 rounded-full colors-surface">
            </div>
            <div class="flex flex-col space-y-1">
              <span class="block text-sm font-medium w-36 colors-surface">&nbsp;</span>
              <span class="block w-44 colors-surface">&nbsp;</span>
            </div>
          </div>
          <span class="sm:flex-row ml-14 w-32 colors-surface">&nbsp;</span>
          <div class="flex flex-col space-y-1 items-center">
            <span class="flex-col sm:flex-row w-36 colors-surface">&nbsp;</span>
            <span class="flex-col sm:flex-row w-28 colors-surface">&nbsp;</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>
