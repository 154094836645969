import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.pre';
import { HttpClient } from '@angular/common/http';
import { Observable, filter, map, switchMap, take, takeWhile, timer } from 'rxjs';
import { TransactionList } from '../models/transaction/transaction-list';
import { Transaction } from '../models/transaction/transaction';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  baseURL = `${environment.apiUrl}businesses`;
  schedules: any[] = []
  scheduling: boolean = false
  schedulingPath: boolean = false
  duplicateTarget: any = {}

  list: any[] = []

  constructor(private http: HttpClient) { }

  getSchedules(page: number, limit: number): Observable<any> {
    return this.http.get<TransactionList>(`${this.baseURL}/schedules?page=${page}&per_page=${limit}`).pipe(
      map((data: any) => {
        this.schedules = data.schedules
        return data;
      })
    );
  }

  getScheduleDetails(id: string): Observable<Transaction> {
    return this.http.get<Transaction>(`${this.baseURL}/schedules/${id}`).pipe(
      map((data: any) => {
        return data as any;
      })
    );
  }

  cancelSchedule(id: string): Observable<any> {
    return this.http.post<Transaction>(`${this.baseURL}/schedules/${id}/cancel`, {});
  }

  // addTransactions(minAmount: number, maxAmount: number, from: string, to: string, concept: string, status: string): Observable<any> {
  addTransactions(page: number = 1, limit: number = 10, searchParams: any): Observable<any> {
    return this.http.get<TransactionList>(`${this.baseURL}/transactions?page=${page}&per_page=${limit}${searchParams.minAmount ? '&min_amount_cents=' + searchParams.minAmount : ''}${searchParams.maxAmount ? '&max_amount_cents=' + searchParams.maxAmount : ''}${searchParams.startDate ? '&start_date=' + searchParams.startDate : ''}${searchParams.endDate ? '&end_date=' + searchParams.endDate : ''}${searchParams.status ? '&status=' + searchParams.status : ''}${searchParams.concept ? '&concept=' + searchParams.concept : ''}`).pipe(
      map((data: TransactionList) => {
        return data
      })
    );
  }

  getTransactions(page: number, limit: number): Observable<any> {
    return this.http.get<TransactionList>(`${this.baseURL}/transactions?page=${page}&per_page=${limit}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getTransactionDetails(id: string): Observable<Transaction> {
    return this.http.get<Transaction>(`${this.baseURL}/transactions/${id}`).pipe(
      map((data: any) => {
        return data.transaction as Transaction;
      })
    );
  }

  checkTransactionStatus(id: string): Observable<Transaction> {
    return timer(0, 1000).pipe(
      switchMap(() => this.getTransactionDetails(id)),
      filter((transaction: Transaction) => transaction.details.status !== 'PENDING'),
      take(1),
      takeWhile((transaction: Transaction) => transaction.details.status === 'PENDING', true)
    );
  }
}
