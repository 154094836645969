import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent implements OnInit {
  @Input() totalResult: number = 20;
  @Input() pageSize: number = 10;
  @Input() currentPage: number = 1;
  @Output() onPageChanged: EventEmitter<number> = new EventEmitter<number>();
  minRange: number = 1;
  maxRange: number = 1;
  pageButtonNumber: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.maxRange = this.pageSize;
    this.minRange = 1;
    this.pageButtonNumber = Math.ceil(this.totalResult / this.pageSize);
    this.computeRange();
  }

  counter(i: number) {
    return new Array(i);
  }

  selectPage(page: number) {
    this.currentPage = page + 1;
    this.computeRange();
    this.onPageChanged.emit(this.currentPage);

  }

  next() {
    if (this.currentPage < this.pageButtonNumber) {
      this.selectPage(this.currentPage);
    }
  }

  previous() {
    if (this.currentPage > 1) {
      this.selectPage(this.currentPage - 2);
    }
  }

  computeRange() {
    this.maxRange = this.currentPage * this.pageSize >= this.totalResult ? this.totalResult : this.currentPage * this.pageSize;
    this.minRange = this.maxRange - this.pageSize + 1;
  }

}
