import { Component } from '@angular/core';

@Component({
  selector: 'app-transaction-details-shimmer',
  standalone: true,
  imports: [],
  templateUrl: './transaction-details-shimmer.component.html',
  styleUrl: './transaction-details-shimmer.component.scss'
})
export class TransactionDetailsShimmerComponent {

}
