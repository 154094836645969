import { EmployeeIn } from "../../employee/employee-in"
import { ProductIn } from "../../product-in"
import { SupplierIn } from "../../suppliers/supplier-in"

export class SimplePaymentOut {
  concept: string = ""
  amount: number = 0
  payee: EmployeeIn | SupplierIn | null = null
  concept_error: boolean = false
  amount_error: boolean = false
  payee_error: boolean = false
  concept_error_message: string = ""
  amount_error_message: string = ""
  payee_error_message: string = ""
  schedulable_type?: string = ""
  schedule_start_date?: string = ""
  schedule_end_date?: string | null = null
  schedule_frequency?: string = ""

  resetErrors() {
    this.concept_error = false
    this.amount_error = false
    this.payee_error = false
    this.concept_error_message = ""
    this.amount_error_message = ""
    this.payee_error_message = ""
  }

  wrapped(product: ProductIn) {
    return Object.assign({}, {
      order: {
        id: null,
        product: { type: product.type, details: { id: product.id, amount: { value: this.amount * 100, currency: "XAF" } } },
        recipient_id: this.payee!!.id,
        recipient_withdrawal_method_id: this.payee!!.main_withdrawal_method.id,
        concept: this.concept,
        schedule: null
      }
    }
    )
  }

  scheduleWrapped(product: ProductIn) {
    return Object.assign({}, {
      schedule: {
        id: null,
        schedulable_type: this.schedulable_type,
        product: { type: product.type, details: { id: product.id, amount: { value: this.amount * 100, currency: "XAF" } } },
        recipient_id: this.payee!!.id,
        recipient_withdrawal_method_id: this.payee!!.main_withdrawal_method.id,
        concept: this.concept,
        schedule_start_date: this.schedule_start_date,
        schedule_end_date: this.schedule_end_date,
        schedule_frequency: this.schedule_frequency
      }
    }
    )
  }
}
