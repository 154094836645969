import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFilter, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { EmployeeIn } from '../../../models/employee/employee-in';
import { Phone } from '../../../models/phone';
import { EmployeesService } from '../../../services/employees.service';
import { SharedService } from '../../../services/shared.service';
import { PaginationComponent } from '../../pagination/pagination.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-full-employees-list',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule, FormsModule, TranslateModule, PaginationComponent],
  templateUrl: './full-employees-list.component.html',
  styleUrl: './full-employees-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullEmployeesListComponent {
  icons = {faFilter, faSearch, faEdit}

  @Input('employees') _employees: EmployeeIn[] = []
  @Input('isLoading') isLoading: Boolean = true
  @Output() onUpdateEmployee = new EventEmitter<EmployeeIn>()

  @Input('selecting') selecting: Boolean = false
  @Input('selectingOne') singleSelection: Boolean = false
  @Input('selectedEmployees') selectedEmployees: EmployeeIn[] = []
  @Output() onEmployeeSelected = new EventEmitter<EmployeeIn>()

  currentPage = 1

  constructor(
    private employeesService: EmployeesService,
    private sharedService: SharedService,
    private router: Router
  ){}

  filter = ""

  handlePageChange(current: number) {
    this.employeesService.listEmployees(current).subscribe(_ => {})
    this.currentPage = current
  }

  get totalResults() {
    return "" == this.filter ? this.employeesService.totalResults : this.employees.length
  }
  get resultsPerPage() {
    return this.employeesService.resultsPerPage
  }

  get employmentTypes() {
    return this.sharedService.staticData.employment_types
  }

  manageEmployee(employeeId: string) {
    this.router.navigate(['dashboard/employees', employeeId])
  }

  changeSelection(employee: EmployeeIn) {
    if (!this.singleSelection) {
      if (!employee.selected) this.selectedEmployees.push(employee)
      else this.selectedEmployees.splice(this.selectedEmployees.findIndex(it => it.id == employee.id), 1)
      this.employees.find(e => e.id == employee.id)!!.selected = !employee.selected
    } else {
      this.employeesService.resetSelection()
      this.onEmployeeSelected.emit(employee)
      this.employees.find(e => e.id == employee.id)!!.selected = !employee.selected
    }
  }

  get employees(): EmployeeIn[] {
    return this._employees.filter(it => it.pageNumber == this.currentPage)
    .filter(item => "" == this.filter || item.fullName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
  }

  filterEmployees() {
    this.employeesService.filterEmployees(this.filter).toPromise()
  }

  trackById(index: number, item: EmployeeIn) {
    return item.id
  }

  get headers() {
    return ['', 'fullname', 'reference', 'employment_type', 'role']
  }

  phone(employee: EmployeeIn): Phone {
    return new Phone(employee.main_withdrawal_method.identifier.substring(4))
  }

  ngOnInit() {
    const selected = this._employees.filter(it => it.selected)
      if (selected.length == 1 && this.singleSelection) this.onEmployeeSelected.emit(this._employees.filter(it => it.selected)[0])
  }
}
