import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBell, faBars, faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Formatters } from '../../helpers/formatters';
import { MenuComponent } from '../menu/menu.component';
import { ProductsService } from '../../services/products.service';
import { SharedService } from '../../services/shared.service';
import { ActivityService } from '../../services/activity.service';

@Component({
  selector: 'app-dashboard-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, FontAwesomeModule, MenuComponent, TranslateModule],
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss'
})
export class DashboardLayoutComponent implements OnInit {
  @Input('actionLink') actionLink: string = ""
  icons = { faBars, faClose, faBell, faPlus }
  menuVisible: Boolean = false
  @Input('hasAction') hasAction: Boolean = true
  @Input('pageTitle') pageTitle: string = "Default, pageTitle"
  @Input('actionLabel') actionLabel: string = "Default, actionLabel"
  @Input() showBalance: boolean = false
  @Input('balanceAmount') amount: number = 0
  @Input('balanceCurrency') currency: string = "XAF"

  @Output() onAction = new EventEmitter()
  @Output() onNotificationClick = new EventEmitter()

  constructor(private activity: ActivityService, private translate: TranslateService, private productService: ProductsService, private sharedService: SharedService) { }

  mouseMoved(_: Event) {
    this.activity.updateActivity()
  }

  ngOnInit(): void {
    this.productService.loadProducts().subscribe(_ => { })
  }

  onNotifClicked() {
    this.onNotificationClick.emit()
  }
  onActionClicked() {
    this.onAction.emit()
  }

  showAmount(): string {
    return Formatters.formatAmount(this.amount, this.currency, this.translate.getDefaultLang())
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible
  }
}
