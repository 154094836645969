import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TransferReceiptComponent } from '../../generators/transfer-receipt/transfer-receipt.component';
import { TransactionService } from '../../services/transaction.service';

@Component({
  selector: 'app-payment-progress',
  standalone: true,
  imports: [CommonModule, TranslateModule, TransferReceiptComponent],
  templateUrl: './payment-progress.component.html',
  styleUrl: './payment-progress.component.scss'
})
export class PaymentProgressComponent implements OnInit {
  @Input() status: string = 'PENDING';
  @Input() mobileOperator: string = '';
  @Input() showPaymentProgress: boolean = false;
  @Input() showButton: boolean = true;
  @Input() showStartOverButton: boolean = false;
  @Input() textButton: string = 'supplier_simple_payment.progress_action_start_over'
  @Input() transaction_id: string = '';
  @Output() buttonAction: EventEmitter<void> = new EventEmitter<void>();


  constructor(private transactionService: TransactionService) { }

  ngOnInit(): void {
  }

  close() {
    history.back();
  }

  buttonActionClicked() {
    this.buttonAction.emit();
  }

}
