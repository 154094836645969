import { CountryCode, parsePhoneNumber, parsePhoneNumberWithError } from "libphonenumber-js"

export class Phone {
    operatorId = ""
    operatorName = ""
    phoneNumber = ""
    private invalid = false

    constructor(identifier: string, country: CountryCode = 'CM')
    {
        let id = null
        try {
            id = parsePhoneNumber(identifier, country)
        }catch (e) {
            this.invalid = true
            return
        }

        const mtnRegex = /^67|^65[0-4]|68[0-3]/;
        const orangeRegex = /^69|^65[5-9]/;

        if (!id.isValid || id.number.length < 13) {
            this.invalid = true
        }

        this.phoneNumber = id.number

        if (mtnRegex.test(identifier)) {
            this.operatorId = "mtn_mobile_money"
            this.operatorName = "MTN Mobile Money"
        } else if (orangeRegex.test(identifier)) {
            this.operatorId = "orange_money"
            this.operatorName = "Orange Money"
        } else this.invalid = true
    }

    get isValid() {
        return !this.invalid
    }
}
