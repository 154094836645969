import { Pipe, PipeTransform } from '@angular/core';
import { format, CountryCode, parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'phoneNumber',
  standalone: true
})
export class PhoneNumberPipe implements PipeTransform {

  transform(phone: number | string, country: string = 'CM') {
    if (!phone) return phone;

    try {
      const formatted = parsePhoneNumber(phone.toString(), country as CountryCode);
      return formatted.formatNational();
    } catch (error) {
      console.error('Invalid phone number:', phone);
      return phone;
    }
  }

}
