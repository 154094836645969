import { Pipe, PipeTransform } from '@angular/core';
import Dinero, { Currency } from "dinero.js";

@Pipe({
  name: 'money',
  standalone: true
})
export class MoneyPipe implements PipeTransform {
  languageRegex = /^fr-[A-Z]{2}$/;
  localeLang: string = this.languageRegex.test(navigator.language) ? 'fr-FR' : navigator.language;

  transform(amount: number, currency: Currency = "XAF"): string {
    return Dinero({ amount: amount, currency: currency })
      .setLocale(this.localeLang)
      .toFormat('$0,0');
  }

}
