<ul class="uppercase fonts-bold">
  <app-menu-item [link]="'/dashboard/home'">
    <fa-icon iconTag [icon]="icons.faDashboard" class="mr-4"></fa-icon>
    <span>{{'menu.dashboard' | translate}}</span>
  </app-menu-item>
  <app-menu-item [link]="'/dashboard/transactions'">
    <fa-icon iconTag [icon]="icons.faTransactions" class="mr-4"></fa-icon>
    <span>{{'menu.transactions' | translate}}</span>
  </app-menu-item>
  <app-menu-item [link]="'/dashboard/schedules'">
    <fa-icon iconTag [icon]="icons.faClock" class="mr-4"></fa-icon>
    <span>{{'menu.schedules' | translate}}</span>
  </app-menu-item>
  <app-menu-item [link]="'/dashboard/employees'">
    <fa-icon iconTag [icon]="icons.faEmployees" class="mr-4"></fa-icon>
    <span>{{'menu.employees' | translate}}</span>
  </app-menu-item>
  <!-- <app-menu-item [link]="'/dashboard/notifications'">
        <fa-icon iconTag [icon]="icons.faNotifications" class="mr-4"></fa-icon>
        <span>{{'menu.notifications' | translate}}</span>
    </app-menu-item> -->
  <app-menu-item [link]="'/dashboard/suppliers'">
    <fa-icon [icon]="icons.faProviders" class="mr-4"></fa-icon>
    <span>{{'menu.suppliers' | translate}}</span>
  </app-menu-item>

  <app-menu-item [link]="'/dashboard/business-account'">
    <div class="flex">
      <div class="w-8 h-8 mr-4 bg-white rounded-full flex items-center justify-center">
        @if (business?.logo !== null) {
        <img [src]="business?.logo" class="w-6 h-6 object-contain" alt="">
        } @else {
        <span class="w-6 h-6 text-center text-[#10004c]">{{business?.name?.charAt(0)}}</span>
        }
      </div>
      <span class="flex-shrink-0">{{'menu.account' | translate}}</span>
    </div>
  </app-menu-item>

  <li>&nbsp;</li>

  <app-menu-item [link]="'/auth/login'">
    <fa-icon [icon]="icons.faSignOut" class="mr-4"></fa-icon>
    <span (click)="onLogout()">{{'menu.disconnect' | translate}}</span>
  </app-menu-item>
  <!-- <app-menu-item [link]="'/dashboard/help'">
        <fa-icon [icon]="icons.faHelp" class="mr-4"></fa-icon>
        <span>{{'menu.help' | translate}}</span>
    </app-menu-item> -->
</ul>
